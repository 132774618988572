import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { ProposalEntities } from 'NewVersion/components/Proposal/ProposalEntities'
import { Proposals } from 'NewVersion/components/Proposal/Proposals'
import Button from 'NewVersion/components/UI/Buttons/Button'
import { RegulationsIcon } from 'NewVersion/icons/RegulationsIcon'
import 'NewVersion/styles/ProposalsStyles.scss'
import * as path from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { getCurrentLanguage } from 'utils/utils'

const ProposalsPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentEdition, loading: loadingCurrentEdition, edition } = useEdition()
  const [regulationOpen, setRegulationOpen] = useState(false)
  const validateForm = () => {
    return false
  }
  if (loadingCurrentEdition || !currentEdition) {
    return <LoadingComponent />
  }

  return (
    <section className="proposals page-wrapper">
      <Dialog
        open={regulationOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="proposalRegulationDialog"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h4">{t('regulations')}</Typography>
        </DialogTitle>
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{
              __html: currentEdition.getRegulationInfo(getCurrentLanguage()),
            }}
          ></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRegulationOpen(false)} color="primary" autoFocus>
            <Typography variant="h5">{t('Cancel·la')}</Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <h1>{t('presentar_proposta_home_short') + ' ' + edition}</h1>
      <div className="proposalsTop-container">
        <div className="addProposals-container">
          <p>{t('presentar_proposta_home_descripcio')}</p>
          {!currentEdition.callIsFinished() ? (
            <>
              <Button
                disabled={validateForm()}
                color="primary"
                type="button"
                dataTest="btnAddProposalsManagerTest"
                onClick={() => {
                  navigate(path.PROPOSALS_FORM_PATH.concat(`?type=manager`))
                }}
              >
                {t('Afegir proposta artística com')}
                <br />
                {t('representant_manager_distibuidor').toLowerCase()}
              </Button>
              <Button
                disabled={validateForm()}
                color="primary"
                type="button"
                dataTest="btnAddProposalsArtistTest"
                onClick={() => {
                  navigate(path.PROPOSALS_FORM_PATH.concat(`?type=artist`))
                }}
              >
                {t('Afegir proposta artística com')}
                <br />
                {t('entity_artist_title').toLowerCase()}
              </Button>
            </>
          ) : (
            <div className="buttons-options">{t('call-is-finished')}</div>
          )}
        </div>
        <div className="regulations-container">
          <p>{t('presentar_normativa_descripcio')}</p>
          <Button
            disabled={validateForm()}
            color="primaryOutlinedIcon outlined"
            type="button"
            icon={<RegulationsIcon />}
            dataTest="btnRegulationsTest"
            onClick={() => setRegulationOpen(true)}
          >
            {t('presentar_normativa')}
          </Button>
        </div>
      </div>

      <div className="separator"></div>

      <div className="artisticProposals-container">
        <h2>
          {t('les_teves_propostes')} {edition}
        </h2>
        {edition && <Proposals year={edition} toggleProposta={() => {}} />}
      </div>

      <div className="separator"></div>

      <div className="manager-container">
        <h5>
          {t('managers')} {edition}
        </h5>
        {edition && <ProposalEntities year={edition} type={'representant'} />}
      </div>

      <div className="separator"></div>

      <div className="artist-container">
        <h5>
          {t('artistaPlural')} {edition}
        </h5>
        {edition && <ProposalEntities year={edition} type={'artista'} />}
      </div>
    </section>
  )
}

export default ProposalsPage

export const getOptionFromValue = (items, value) => {
  return (items && value && items.find((o) => o.value === value)) || undefined
}
export const getOptionsFromValue = (items, value) => {
  return (items && value && items.filter((o) => value.includes(o.value))) || []
}
export const getOptionsFormat = (items, t, name) => {
  const options = []
  items.forEach((item) => {
    options.push({ label: t(item), value: item })
  })

  options.sort((a, b) => {
    if (b.value === 'lang-notext') {
      return 1
    }
    if (a.value === 'cap') {
      return 1
    }
    return a.label.localeCompare(b.label)
  })
  return options
}

export const getValuesOfReactSelectValues = (data) => {
  const arrayOptions = []
  if (data && data.length !== 0) {
    data.forEach((v) => {
      arrayOptions.push(v.value)
    })
  }
  return arrayOptions
}
